import React, {useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';
import {MiniChart } from "react-ts-tradingview-widgets";
import Markets from '../Home/Markets';

const MarketsAccount = () => {
  const [coinSymbol, setCoinSymbol] = useState('BINANCE:BTCUSDT');
  return (
    <div>
      <Card sx={{height: "200px", marginTop: '65px', borderRadius: '15px', overflow: 'hidden'}}>
        <CardContent>
            <MiniChart symbol= {coinSymbol}
                height= {180}
                locale= "en"
                dateRange= "12M"
                colorTheme= "dark"
                trendLineColor= "rgba(6, 222, 156, 1)"
                underLineColor= "rgba(136, 241, 195, 0.16)"
                underLineBottomColor= "rgba(41, 98, 255, 0)"
                isTransparent= {true}
                autosize= {false}
                width="100%"/>
        </CardContent>
      </Card>

      <Markets/>
    </div>
  )
}

export default MarketsAccount
