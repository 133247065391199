
import axios from "axios";
import { useSelector } from "react-redux";

axios.defaults.withCredentials = true;
const access_token = localStorage.getItem('auth-token');

export default axios.create({
  baseURL: 'https://api.idenyiezeugwuinvestments.com',
  headers: {
    "Content-type": "application/json",
    withCredentials: true,
    Authorization: `Bearer ${access_token}`
  }
});